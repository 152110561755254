body {
  margin: 0;
  font-family: 'Roboto', sans-serif; /* Or your preferred font */
  background-color: #191a1a;
  color: #ffffff;
}

/* Container colors */
.main-container {
  background-color: #191a1a; 
}
